import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Divider from "./Divider";

class MultiColSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="album py-5 animated" data-section={this.props.section}>
        <div className="container">
          {this.props.section === "sectionOne" ? (
            <div className="row">
              <div className="col-md-12">
                <h2 className="txtCenter" data-target={this.props.sectionTitle}>
                  {this.props.sectionTitle}
                </h2>
                <hr />
              </div>
            </div>
          ) : null}
          <div className="row equalize1">
            {this.props.sectionData.map((content, i) => {
              return (
                <div data-equalize={i} className={this.props.theClass} key={i}>
                  {ReactHtmlParser(content)}
                </div>
              );
            })}
          </div>
          <Divider />
        </div>
      </div>
    );
  }
}

export default MultiColSection;
