import React, { Component } from "react";
import SocialMedia from "./SocialMedia";

class SocialMediaTop extends Component {
  constructor(props) {
    super(props);
    this.State = {};
    this.overTada = this.overTada.bind(this);
    this.outTada = this.outTada.bind(this);
  }

  overTada() {
    document
      .querySelector(".animated[data-item='topTitle']")
      .classList.add("tada");
  }
  outTada() {
    document
      .querySelector(".animated[data-item='topTitle']")
      .classList.remove("tada");
  }

  render() {
    return (
      <div
        className="container-fluid p-3 px-md-4 bg-dark border-bottom shadow-sm"
        id="top"
      >
        <div className="row">
          <div className="col-md-3">
            <h3
              className="my-0 mr-md-auto font-weight-normal animated"
              data-item="topTitle"
              onMouseOver={this.overTada}
              onMouseOut={this.outTada}
            >
              {this.props.topTitle}
            </h3>

            <a href="#top" id="backToTop">
              <i className="fas fa-arrow-up" />
            </a>

            {this.props.editMode ? (
              <div className="form-group editMode">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  name="topTitle"
                  data-regex="any"
                  maxLength="20"
                />
                <button
                  className="btn btn-primary btn-block"
                  onClick={this.props.updateThis.bind(this, "topTitle")}
                >
                  Update Title
                </button>
              </div>
            ) : null}
          </div>

          <div className="col-md-6 justify-content-md-center">
            <ul className="inline contactNav">
              <li>
                <i className="fas fa-home" />
                {this.props.address}
                {this.props.editMode ? (
                  <div className="form-group editMode">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Change: " + this.props.address}
                      name="address"
                      data-regex="any"
                      maxLength="40"
                    />
                    <button
                      className="btn btn-primary btn-block"
                      onClick={this.props.updateThis.bind(this, "address")}
                    >
                      Update Address
                    </button>
                  </div>
                ) : null}
              </li>
              <li>
                <a href={"tel:" + this.props.phone} className="mailLink">
                  <i className="fas fa-phone" />
                  {this.props.phone}
                </a>
                {this.props.editMode ? (
                  <div className="form-group editMode">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Change: " + this.props.phone}
                      name="phone"
                      data-regex="phone"
                      maxLength="12"
                    />
                    <button
                      className="btn btn-primary btn-block"
                      onClick={this.props.updateThis.bind(this, "phone")}
                    >
                      Update Phone
                    </button>
                  </div>
                ) : null}
              </li>
              <li>
                <i className="fas fa-envelope" />
                <a className="mailLink" href={"mailto:" + this.props.email}>
                  {this.props.email}
                </a>
                {this.props.editMode ? (
                  <div className="form-group editMode">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Change: " + this.props.email}
                      name="email"
                      data-regex="email"
                      maxLength="25"
                    />
                    <button
                      className="btn btn-primary btn-block"
                      onClick={this.props.updateThis.bind(this, "email")}
                    >
                      Update Email
                    </button>
                  </div>
                ) : null}
              </li>
            </ul>
          </div>

          <div className="col-md-3 topNavSocial">
            <SocialMedia
              rssFeed={this.props.rssFeed}
              instagram={this.props.instagram}
              facebook={this.props.facebook}
              twitter={this.props.twitter}
              linkedin={this.props.linkedin}
              youtube={this.props.youtube}
              rss={this.props.rss}
              donate={this.props.donate}
              socialEdit="false"
              location="top"
              uid={this.props.uid}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SocialMediaTop;
