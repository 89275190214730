import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      feed: "",
      value: this.props.rssFeed,
      blogScroll: 0,
      maxPost: 0,
    };
    this.getRssFeed = this.getRssFeed.bind(this);
    this.viewPosts = this.viewPosts.bind(this);
  }

  viewPosts(direction) {
    [].forEach.call(document.querySelectorAll(".post[data-num]"), function (e) {
      e.classList.add("hide");
    });
    if (document.querySelector(".fadeIn")) {
      [].forEach.call(document.querySelectorAll(".fadein"), function (e) {
        e.classList.remove("fadeIn");
      });
    }

    const cardLength = this.state.maxPost;

    let blogScroll = this.state.blogScroll;
    let visibleCards = blogScroll / cardLength;

    if (direction === "next") {
      blogScroll = blogScroll + 1;
      if (blogScroll >= cardLength) {
        blogScroll = 0;
      }
      this.setState({
        blogScroll,
      });
    } else {
      blogScroll = blogScroll - 1;
      if (blogScroll < 0) {
        blogScroll = cardLength - 1;
      }
      this.setState({
        blogScroll,
      });
    }

    if (document.querySelector(".post[data-num='" + blogScroll + "']")) {
      document
        .querySelector(".post[data-num='" + blogScroll + "']")
        .classList.remove("hide");
      document
        .querySelector(".post[data-num='" + blogScroll + "']")
        .classList.add("fadeIn");
    }
  }

  getRssFeed(whatFeed) {
    fetch(whatFeed)
      .then((res) => res.text())
      .then(
        (result) => {
          this.setState(
            {
              isLoaded: true,
              feed: result,
            },
            () => {
              const cardLength = document.getElementsByClassName("post").length;
              this.setState({
                maxPost: cardLength,
              });
            }
          );
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  componentDidMount() {
    this.getRssFeed(
      "https://mechanized-aesthetics.net/php-relays/any-blog-value.php?xml=" +
        this.state.value
    );
  }

  render() {
    return (
      <div className="album py-5" data-section="blogSection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>
                RSS Feed/Bog Posts:{" "}
                {this.state.maxPost !== 0
                  ? this.state.blogScroll + 1 + "/" + this.state.maxPost
                  : null}
              </h1>
              <div className="btn-group btn-block">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.viewPosts.bind(this, "previous")}
                >
                  Previous Post
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.viewPosts.bind(this, "next")}
                >
                  Next Post
                </button>
              </div>
            </div>
            {this.state.feed ? (
              ReactHtmlParser(this.state.feed)
            ) : (
              <div className="loader center" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
