import React, { Component } from "react";

import SocialMediaTop from "./SocialMediaTop";
import Jumbotron from "./Jumbotron";
import MainFocus from "./MainFocus";
import Carousel from "./Carousel";
import OptionSection from "./OptionSection";
import ListSection from "./ListSection";
import SocialMedia from "./SocialMedia";
import Contact from "./Contact";
//import Reservations from "./Reservations";
import result from "./localData/wolffMotelData.json";
import Weather from "./Weather";
import Blog from "./Blog";
import "../node_modules/react-quill/dist/quill.snow.css"; // ES6
//import Data from "./localData/Data.js";

/*import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css"; //https://fontawesome.com/icons?d=gallery*/

class App extends Component {
  constructor() {
    super();
    this.state = {
      localData: [],
      editMode: false,
      copyrightYr: "",
      uid: "d59OkXCzYSe0GJ3xNIyAMXrYXFW2",
      isAnonymous: true,
      adminLogin: false,
      jumbotronHeading: "",
      jumbotronImg: "",
      bannerTxtColor: "",
      teasers: [],
      design: "",
      topTitle: "",
      address: "",
      phone: "",
      email: "",
      events: [],
      loggedIn: "",
      adminLogin: false,
      alertMessage: "",
      rssFeed: "",
      isLoaded: false,
      error: "",
      sectionOne: "",
      sectionTwo: "",
      sectionThree: "",
      sectionFour: "",
    };

    this.scrollWindow = this.scrollWindow.bind(this);
    this.setData = this.setData.bind(this)
  }

  scrollWindow(scrollTarget) {
    console.log("scrollTarget: " + scrollTarget);
    console.log("this.state.editMode: " + this.state.editMode);
    if (this.state.editMode === false) {
      document
        .querySelector("[data-target='" + scrollTarget + "']")
        .scrollIntoView({ behavior: "smooth" });
      console.log("should be scrolling");
    }
  }


  setData(result) {
    this.setState({
      localData: result,
      jumbotronHeading: result.jumbotronHeading,
      jumbotronLead: result.jumbotronLead,
      jumbotronImg: result.jumbotronImg,
      bannerTxtColor: result.bannerTxtColor,
      teasers: JSON.parse(result.teasers),
      design: result.design,
      topTitle: result.topTitle,
      address: result.address,
      phone: result.phone,
      email: result.email,
      //rssFeed: result.rss,
      rssFeed: "",
      instagram: result.instagram,
      facebook: result.facebook,
      twitter: result.twitter,
      linkedin: result.linkedin,
      youtube: result.youtube,
      rss: result.rss,
      donate: result.donate,
      events: JSON.parse(result.events),
      secondaryContent: JSON.parse(result.secondaryContent),
      sectionOne: JSON.parse(result.sectionOne),
      sectionTwo: JSON.parse(result.sectionTwo),
      //sectionThree: result.sectionThree.d59OkXCzYSe0GJ3xNIyAMXrYXFW2,
      //sectionFour: result.sectionFour.d59OkXCzYSe0GJ3xNIyAMXrYXFW2,
    });
  }


  componentWillMount() {

    this.setData(result);
    /*fetch(
  "https://mechanized-aesthetics.net/WEB-TEMPLATE-DATA/" +
  this.state.uid +
  "/get-data-" +
  this.state.uid +
  ".php"
)
  .then((res) => res.json())
  .then(
    (result) => {
      this.setData(result);
    },
    // Note: it's important to handle errors here
    // instead of a catch() block so that we don't swallow
    // exceptions from actual bugs in components.
    (error) => {
      this.setState({
        isLoaded: true,
        error,
      });
    }
  );
*/
    var d = new Date();
    var yr = d.getFullYear();

    this.setState({
      copyrightYr: yr,
    });
  }

  render() {
    if (this.state.topTitle) {
      document.title = this.state.topTitle;
    }

    document
      .querySelector("body")
      .setAttribute("data-design", this.state.design);

    const localData = this.state.localData;



    return (
      <React.Fragment>
        <div>
          <SocialMediaTop
            uid={this.state.uid}
            rssFeed={this.state.rssFeed}
            instagram={this.state.instagram}
            facebook={this.state.facebook}
            twitter={this.state.twitter}
            linkedin={this.state.linkedin}
            youtube={this.state.youtube}
            rss={this.state.rss}
            donate={this.state.donate}
            topTitle={this.state.topTitle}
            address={this.state.address}
            phone={this.state.phone}
            email={this.state.email}
            editMode={this.state.editMode}
            teasers={this.state.teasers}
            scrollWindow={this.scrollWindow}
          />
          <Jumbotron
            uid={this.state.uid}
            editMode={this.state.editMode}
            teasers={this.state.teasers}
            scrollWindow={this.scrollWindow}
            jumbotronImg={this.state.jumbotronImg}
            jumbotronHeading={this.state.jumbotronHeading}
            jumbotronLead={this.state.jumbotronLead}
            bannerTxtColor={this.state.bannerTxtColor}
          />
          <main>
            {this.state.teasers.length > 0 ? (
              <React.Fragment>
                <MainFocus
                  teasers={this.state.teasers}
                  scrollWindow={this.scrollWindow}
                />
                <Carousel
                  uid={this.state.uid}
                  sectionTitle={this.state.teasers[0].teaserTitle}
                  secondaryContent={this.state.secondaryContent}
                />

                <OptionSection
                  uid={this.state.uid}

                  sectionColor="whiteBg"
                  sectionNum="1"
                  sectionOne={this.state.sectionOne}
                  sectionTwo={this.state.sectionTwo}
                  sectionThree={this.state.sectionThree}
                  sectionFour={this.state.sectionFour}
                />

                <Weather />
                <ListSection
                  events={this.state.events}
                  sectionTitle={this.state.teasers[2].teaserTitle}
                  scrollWindow={this.scrollWindow}
                />
                {this.state.rssFeed.length > 0 ? (
                  <Blog rssFeed={this.state.rssFeed} />
                ) : null}
                <Contact
                  sectionTitle={this.state.teasers[3].teaserTitle}
                  topTitle={this.state.topTitle}
                  address={this.state.address}
                  phone={this.state.phone}
                  email={this.state.email}
                />{" "}
              </React.Fragment>
            ) : (
              <div className="loader center" />
            )}
          </main>
          <footer className="footer mt-auto py-3 bg-dark">
            <div className="container">
              <div className="row">
                <div className="col-md-4 text-muted  txtCenter">
                  <i className="far fa-copyright" /> {this.state.copyrightYr}{" "}
                  Web-Presence LLC
                </div>
                <div className="col-md-4 text-muted  txtCenter">
                  <a
                    href="./termsAndConditions.html"
                    className="text-muted"
                    target="_blank"
                  >
                    Terms & Conditions
                  </a>
                </div>
                <div className="col-md-4 txtCenter">
                  <SocialMedia
                    rssFeed={this.state.rssFeed}
                    instagram={this.state.instagram}
                    facebook={this.state.facebook}
                    twitter={this.state.twitter}
                    linkedin={this.state.linkedin}
                    youtube={this.state.youtube}
                    rss={this.state.rss}
                    donate={this.state.donate}
                    socialEdit="true"
                    editMode={this.state.editMode}
                    location="bottom"
                    bounceIn={this.bounceIn}
                    bounceOut={this.bounceIn}
                  />
                </div>
              </div>
            </div>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}
export default App;
