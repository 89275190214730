import React, { Component } from "react";

class ModalCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeImg: 0,
    };
    this.changeModalImg = this.changeModalImg.bind(this);
  }

  changeModalImg(direction) {
    const activeImg = this.state.activeImg;
    if (direction === "next") {
      if (activeImg < this.props.modalContent.length - 1) {
        this.setState({
          activeImg: Number(activeImg + 1),
        });
      } else {
        this.setState({
          activeImg: 0,
        });
      }
    }

    if (direction === "prev") {
      if (this.state.activeImg <= 0) {
        this.setState({
          activeImg: this.props.modalContent.length - 1,
        });
      } else {
        this.setState({
          activeImg: Number(activeImg - 1),
        });
      }
    }
  }
  render() {
    return (
      <div
        className={
          this.props.showModal
            ? "modal fade show animated bounceInDown"
            : "modal"
        }
        style={
          this.props.showModal ? { display: "block" } : { display: "none" }
        }
        id="galleryModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {this.props.secondaryTitle}{" "}
                {this.state.activeImg +
                  1 +
                  "/" +
                  this.props.modalContent.length}
              </h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.props.hideGalleryModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {this.props.modalContent.length > 1 ? (
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.changeModalImg.bind(this, "prev")}
                >
                  <i className="fas fa-arrow-circle-left" /> Previous Image
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.changeModalImg.bind(this, "next")}
                >
                  Next Image <i className="fas fa-arrow-circle-right" />
                </button>
              </div>
            ) : null}
            <div className="modal-body">
              <img
                src={this.props.modalContent[this.state.activeImg]}
                className="img-fluid"
              />
            </div>{" "}
            <button
              className="btn btn-block btn-secondary"
              onClick={this.props.hideGalleryModal}
            >
              Close Window
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalCarousel;
