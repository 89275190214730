import React, { Component } from "react";

class Contact extends Component {
  constructor() {
    super();
    this.contactScroll = this.contactScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.contactScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.contactScroll);
  }

  contactScroll() {
    const wHeight = window.innerHeight;

    let sectionContact = document
      .querySelector("div[data-contact='1']")
      .getBoundingClientRect().top; // Y
    if (sectionContact < wHeight - 10) {
      document.querySelector("div[data-contact='1']").classList.add("fadeIn");
      document.querySelector("div[data-contact='2']").classList.add("fadeIn");
    }
  }

  render() {
    return (
      <div className="album py-5" data-section="sectionFour">
        <div className="container">
          <div className="row">
            <div className="col-md-12" data-target="contact">
              <h2 className="txtCenter" data-target={this.props.sectionTitle}>
                {this.props.sectionTitle}
              </h2>
              <hr />
            </div>
          </div>
          <div className="row animated" data-contact="1">
            <div className="col-md-3 txtCenter">
              <label>{this.props.topTitle}: </label>
            </div>
            <div className="col-md-3 txtCenter">
              <label>
                <i className="fas fa-home" /> {this.props.address}
              </label>
            </div>
            <div className="col-md-3 txtCenter">
              <label>
                <a href={"tel:" + this.props.phone}>
                  <i className="fas fa-phone" /> {this.props.phone}
                </a>
              </label>
            </div>
            <div className="col-md-3 txtCenter">
              <label>
                <a href={"mailto:" + this.props.email}>
                  <i className="fas fa-envelope" /> {this.props.email}
                </a>
              </label>
            </div>
          </div>
          <div className="row animated" data-contact="2">
            <div className="col-md-12" />
            <hr />


            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3472.2312568188913!2d-97.45113168481063!3d29.509617782080035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86433e17f5a90e93%3A0x2db5f5d91687ec6f!2s1020%20Henry%20St%2C%20Gonzales%2C%20TX%2078629!5e0!3m2!1sen!2sus!4v1626790612569!5m2!1sen!2sus" className="contactMap" allowFullScreen loading="lazy"></iframe>


          </div>
        </div>
      </div>
    );
  }
}

export default Contact;

/*            <iframe src="https://www.google.com/maps/d/embed?mid=1BU40LraIFvcw91UfhpyS1xFiqAI-9Mgl" className="contactMap" allowFullScreen></iframe>

      address: "1020 Henry Street Gonzales, TX 78629",
      phone: "1 830-519-4177",
      email: "owner@wolffmotel.com",

<iframe src="https://calendar.google.com/calendar/embed?src=ajttpoqtkg3r9568mqvus56h4s%40group.calendar.google.com&ctz=America%2FChicago" style="border: 0" width="800" height="600" frameborder="0" scrolling="no"></iframe>

            <iframe
              className="contactMap"
              src="https://www.google.com/maps/d/embed?mid=1BU40LraIFvcw91UfhpyS1xFiqAI-9Mgl"
              allowFullScreen
            />


*/

/*WILSON FUNDRAISER



                  <iframe
              className="contactMap"
              src={
                "https://www.google.com/maps/embed/v1/place?key=AIzaSyBxvGBPN_lRhoYskabk_lZ5FAo4GIowU6I&q=" +
                this.props.address
              }
              allowFullScreen
            />


            */
