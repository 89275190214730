import React, { Component } from "react";

class MainFocus extends Component {
  constructor() {
    super();
    this.state = {};

    this.teaserTransitionOver = this.teaserTransitionOver.bind(this);
    this.teaserTransitionOut = this.teaserTransitionOut.bind(this);
  }

  componentDidMount() {
    setTimeout(function () {
      document.querySelector(".mainFocusBg").classList.remove("hide");
      document.querySelector(".mainFocusBg").classList.add("animated");
      document.querySelector(".mainFocusBg").classList.add("fadeIn");
    }, 100);
  }

  teaserTransitionOver(whichTeaser) {
    document
      .querySelector("div[data-teaser='" + whichTeaser + "']")
      .classList.remove("fadeIn");

    document
      .querySelector("img[data-img='" + whichTeaser + "']")
      .classList.add("pulse");

    document
      .querySelector("div[data-focus='" + whichTeaser + "']")
      .classList.remove("fadeOut");

    document
      .querySelector("div[data-teaser='" + whichTeaser + "']")
      .classList.add("fadeOutDown");

    document
      .querySelector("div[data-focus='" + whichTeaser + "']")
      .classList.add("fadeIn");
  }

  teaserTransitionOut(whichTeaser) {
    document
      .querySelector("div[data-teaser='" + whichTeaser + "']")
      .classList.remove("fadeOutDown");

    document
      .querySelector("img[data-img='" + whichTeaser + "']")
      .classList.remove("pulse");

    document
      .querySelector("div[data-teaser='" + whichTeaser + "']")
      .classList.add("fadeIn");

    document
      .querySelector("div[data-focus='" + whichTeaser + "']")
      .classList.add("fadeOut");
  }

  render() {
    return (
      <div className="album py-5 mainFocusBg hide hideAt768">
        <div className="container">
          <div className="row moveUp">
            {this.props.teasers && this.props.teasers.length > 0
              ? this.props.teasers.map((tease, i) => {
                  return (
                    <div
                      className="col-md-3 teaser"
                      onClick={this.props.scrollWindow.bind(
                        this,
                        tease.teaserTitle
                      )}
                      key={i}
                    >
                      <div
                        className="card mb-4 shadow-sm img-thumbnail"
                        onMouseOver={this.teaserTransitionOver.bind(this, i)}
                        onMouseOut={this.teaserTransitionOut.bind(this, i)}
                      >
                        <div
                          className="focusTeaser animated center"
                          data-focus={i}
                        >
                          <h1>{tease.focusTeaser}</h1>
                        </div>
                        <div
                          className="mainTease animated"
                          key={i}
                          data-teaser={i}
                        >
                          <h5>{tease.teaserTitle}</h5>
                          <p>{tease.teaserText}</p>
                        </div>
                        <img
                          data-img={i}
                          className="animated"
                          src={tease.teaserImg}
                        />
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  }
}

export default MainFocus;
