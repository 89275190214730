import React, { Component } from "react";
import MultiColSection from "./MultiColSection";

class OptionSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editSection: "sectionOne",
      sectionOne: this.props.sectionOne,
      sectionTwo: this.props.sectionTwo,
      sectionThree: this.props.sectionThree,
      sectionFour: this.props.sectionFour,
      tempNames: [],
      tempContent: [],
    };
    this.bounceScroll = this.bounceScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.bounceScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.bounceScroll);
  }

  bounceScroll() {
    const wHeight = window.innerHeight;

    if (this.state.sectionOne.length > 0) {
      let sectionOne = document
        .querySelector("div[data-section='sectionOne']")
        .getBoundingClientRect().top; // Y
      if (sectionOne < wHeight - 10) {
        document
          .querySelector("div[data-section='sectionOne']")
          .classList.add("fadeIn");
      }
    }

    if (this.state.sectionTwo.length > 0) {
      let sectionTwo = document
        .querySelector("div[data-section='sectionTwo']")
        .getBoundingClientRect().top; // Y
      if (sectionTwo < wHeight - 300) {
        document
          .querySelector("div[data-section='sectionTwo']")
          .classList.add("fadeIn");
      }
    }
    if (this.state.sectionThree.length > 0) {
      let sectionThree = document
        .querySelector("div[data-section='sectionThree']")
        .getBoundingClientRect().top; // Y
      if (sectionThree < wHeight - 300) {
        document
          .querySelector("div[data-section='sectionThree']")
          .classList.add("fadeIn");
      }
    }
    if (this.state.sectionFour.length > 0) {
      let sectionFour = document
        .querySelector("div[data-section='sectionFour']")
        .getBoundingClientRect().top; // Y
      if (sectionFour < wHeight - 300) {
        document
          .querySelector("div[data-section='sectionFour']")
          .classList.add("fadeIn");
      }
    }
  }

  render() {
    let guestUser = this.props.uid;
    let sectionOneColNum = "12";
    switch (this.state.sectionOne.length) {
      case 2:
        sectionOneColNum = "6";
        break;
      case 3:
        sectionOneColNum = "4";
        break;
      case 4:
        sectionOneColNum = "3";
        break;
    }

    let sectionTwoColNum = "12";
    if (this.state.sectionTwo) {
      switch (this.state.sectionTwo.length) {
        case 2:
          sectionTwoColNum = "6";
          break;
        case 3:
          sectionTwoColNum = "4";
          break;
        case 4:
          sectionTwoColNum = "3";
          break;
      }
    }

    let sectionThreeColNum = "12";

    if (this.state.sectionThree.length > 0) {
      switch (this.state.sectionThree.length) {
        case 2:
          sectionThreeColNum = "6";
          break;
        case 3:
          sectionThreeColNum = "4";
          break;
        case 4:
          sectionThreeColNum = "3";
          break;
      }
    }
    let sectionFourColNum = "12";
    if (this.state.sectionFour.length > 0) {
      switch (this.state.sectionFour.length) {
        case 2:
          sectionFourColNum = "6";
          break;
        case 3:
          sectionFourColNum = "4";
          break;
        case 4:
          sectionFourColNum = "3";
          break;
      }
    }

    return (
      <React.Fragment>
        {this.props.sectionOne.length > 0 ? (
          <MultiColSection
            sectionTitle={this.props.sectionTitle}
            section={"sectionOne"}
            sectionData={this.state.sectionOne}
            newContent="false"
            theClass={"col-md-" + sectionOneColNum}
          />
        ) : null}

        {this.state.sectionTwo.length > 0 ? (
          <MultiColSection
            sectionTitle={this.props.sectionTitle}
            section={"sectionTwo"}
            sectionData={this.state.sectionTwo}
            newContent="false"
            theClass={"col-md-" + sectionTwoColNum}
          />
        ) : null}

        {this.state.sectionThree.length > 0 ? (
          <MultiColSection
            sectionTitle={this.props.sectionTitle}
            section={"sectionThree"}
            sectionData={this.state.sectionThree}
            newContent="false"
            theClass={"col-md-" + sectionThreeColNum}
          />
        ) : null}

        {this.state.sectionFour.length > 0 ? (
          <MultiColSection
            sectionTitle={this.props.sectionTitle}
            section={"sectionFour"}
            sectionData={this.state.sectionFour}
            newContent="false"
            theClass={"col-md-" + sectionFourColNum}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default OptionSection;
