import React, { useState, useEffect } from "react";
import WeatherAPI from "./config/WeatherAPI";

const Info = () => {
    let [theWeather, setTheWeather] = useState(null);
    let [loaded, setLoaded] = useState(false);


    const grabWeather = () => {

        fetch("https://api.openweathermap.org/data/2.5/forecast?zip=78629,US&appid=" + WeatherAPI + "&units=imperial")
            .then((res) => res.text())
            .then(
                (result) => {
                    const tempData = JSON.parse(result);

                    if (tempData.cod !== "404") {

                        /* let tempDates = [];
                         let singleDates = [];
                         for (let i = 0; i < tempData.list.length; i++) {
                           if (tempDates.indexOf(tempData.list[i].dt_txt.substring(0, 10)) === -1 && tempDates.length < 5) {
                             tempDates.push(tempData.list[i].dt_txt.substring(0, 10));
                             singleDates.push(tempData.list[i]);
                           }
                         }*/

                        let tempDates = [];
                        let singleDates = [];
                        let whichDay = "";
                        let minTemps = [];
                        let maxTemps = [];
                        let lowest = 0;
                        let highest = 0;
                        let dateList = [];
                        for (let i = 0; i < tempData.list.length; i++) {
                            if (whichDay !== tempData.list[i].dt_txt.substring(0, 10)) {
                                tempDates.push({
                                    day: tempData.list[i].dt_txt.substring(0, 10),
                                    min: 0,
                                    max: 0
                                });
                                whichDay = tempData.list[i].dt_txt.substring(0, 10);
                                minTemps = [tempData.list[i].main.temp_min];
                                maxTemps = [tempData.list[i].main.temp_max];
                                lowest = tempData.list[i].main.temp_min;
                                highest = tempData.list[i].main.temp_max;
                            } else {
                                minTemps.push(tempData.list[i].main.temp_min);
                                maxTemps.push(tempData.list[i].main.temp_max);
                                lowest = Math.min(...minTemps);
                                highest = Math.max(...maxTemps);
                                for (let j = 0; j < tempDates.length; j++) {
                                    if (tempDates[j].day === tempData.list[i].dt_txt.substring(0, 10)) {
                                        tempDates[j].min = lowest;
                                        tempDates[j].max = highest;
                                    }
                                }
                            }

                        }
                        for (let i = 0; i < tempData.list.length; i++) {
                            for (let j = 0; j < tempDates.length; j++) {
                                if (tempDates[j].day === tempData.list[i].dt_txt.substring(0, 10) && tempDates[j].max !== 0 && tempDates[j].min !== 0) {
                                    tempData.list[i].main.temp_min = tempDates[j].min;
                                    tempData.list[i].main.temp_max = tempDates[j].max;
                                }
                            }
                        }
                        for (let i = 0; i < tempData.list.length; i++) {
                            if (dateList.indexOf(tempData.list[i].dt_txt.substring(0, 10)) === -1) {
                                dateList.push(tempData.list[i].dt_txt.substring(0, 10));
                                if (singleDates.length <= 4) {
                                    singleDates.push(tempData.list[i]);
                                }
                            }
                        }
                        setTheWeather((theWeather) => singleDates);
                    } else {
                        console.log("That returned a code: " + tempData.cod);
                    }
                },
                (error) => {
                    console.log("error: " + error);
                }
            );


    }

    useEffect(() => {
        if (loaded === false && theWeather === null) {
            grabWeather();
            setLoaded((loaded) => true);
        }
    })
    return (
        <div className="album py-5" data-section="listSection" >
            <div className="container">
                <div className="row">

                    <div className="col-md-12"><h2 className="txtCenter" data-target="Upcoming Events">Gonzales, Texas</h2><hr ></hr></div>

                    <div className="col-md-12 center">

                        <div className="alert alert-dark opacityImg" role="alert">


                            <ul className="inline weather center">
                                {theWeather !== null
                                    ? theWeather.map((date, i) => {


                                        return (
                                            <li key={i}>
                                                <img
                                                    src={"https://openweathermap.org/img/wn/" + date.weather[0].icon + "@2x.png"}
                                                    className="img-fluid"
                                                />
                                                <div className="center weathertxt">
                                                    {date.dt_txt.substring(5, 10)}<br />{date.weather[0].description}
                                                </div>
                                                <div className="center">
                                                    {"High: " + date.main.temp_max + " - Low: " + date.main.temp_min}
                                                </div>
                                            </li>
                                        );


                                    })
                                    : null}
                            </ul>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )

}

export default Info;



/*
[{"dt":1714068000,"main":{"temp":80.19,"feels_like":83.64,"temp_min":80.19,"temp_max":80.19,"pressure":1014,"sea_level":1014,"grnd_level":1002,"humidity":73,"temp_kf":0},

"weather":[{"id":500,"main":"Rain","description":"light rain","icon":"10d"}],"clouds":{"all":98},"wind":{"speed":15.43,"deg":162,"gust":23.17},"visibility":10000,"pop":0.2,"rain":{"3h":0.26},"sys":{"pod":"d"},"dt_txt":"2024-04-25 18:00:00"},{"dt":1714089600,"main":{"temp":78.21,"feels_like":79.3,"temp_min":77.22,"temp_max":78.21,"pressure":1011,"sea_level":1011,"grnd_level":998,"humidity":76,"temp_kf":0.55},

"weather":[{"id":500,"main":"Rain","description":"light rain","icon":"10d"}],"clouds":{"all":99},"wind":{"speed":17.31,"deg":152,"gust":27.02},"visibility":10000,"pop":0.2,"rain":{"3h":0.12},"sys":{"pod":"d"},"dt_txt":"2024-04-26 00:00:00"},{"dt":1714176000,"main":{"temp":78.67,"feels_like":79.43,"temp_min":78.67,"temp_max":78.67,"pressure":1007,"sea_level":1007,"grnd_level":995,"humidity":68,"temp_kf":0},

"weather":[{"id":803,"main":"Clouds","description":"broken clouds","icon":"04d"}],"clouds":{"all":52},"wind":{"speed":17.54,"deg":153,"gust":26.15},"visibility":10000,"pop":0,"sys":{"pod":"d"},"dt_txt":"2024-04-27 00:00:00"},{"dt":1714262400,"main":{"temp":76.91,"feels_like":78.3,"temp_min":76.91,"temp_max":76.91,"pressure":1007,"sea_level":1007,"grnd_level":996,"humidity":85,"temp_kf":0},

"weather":[{"id":500,"main":"Rain","description":"light rain","icon":"10d"}],"clouds":{"all":39},"wind":{"speed":19.8,"deg":144,"gust":31.41},"visibility":10000,"pop":0.2,"rain":{"3h":0.23},"sys":{"pod":"d"},"dt_txt":"2024-04-28 00:00:00"},{"dt":1714348800,"main":{"temp":74.84,"feels_like":76.35,"temp_min":74.84,"temp_max":74.84,"pressure":1008,"sea_level":1008,"grnd_level":996,"humidity":92,"temp_kf":0},

"weather":[{"id":500,"main":"Rain","description":"light rain","icon":"10d"}],"clouds":{"all":89},"wind":{"speed":3.53,"deg":272,"gust":7.02},"visibility":10000,"pop":1,"rain":{"3h":1.01},"sys":{"pod":"d"},"dt_txt":"2024-04-29 00:00:00"}]


*/
