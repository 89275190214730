import React, { Component } from "react";

class Jumbotron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jumbotronHeading: "",
      jumbotronLead: "",
      jumbotronImg: "",
      bannerTxtColor: "",
    };
  }

  render() {
    const teasers = this.props.teasers;

    return (
      <div>
        <section
          className="jumbotron text-center"
          style={{ backgroundImage: "url(" + this.props.jumbotronImg + ")" }}
        >
          <div className="container">
            <h1 className={"jumbotron-heading " + this.props.bannerTxtColor}>
              {this.props.jumbotronHeading}
            </h1>{" "}
            {this.props.editMode ? (
              <div className="form-group editMode">
                <input
                  className="form-control"
                  placeholder={"Change: " + this.props.jumbotronHeading}
                  name="jumbotronHeading"
                />
                <button
                  className="btn btn-primary btn-block"
                  onClick={this.updateJombotron.bind(this, "jumbotronHeading")}
                >
                  Change Banner Title
                </button>
              </div>
            ) : null}
            <p className={"lead " + this.props.bannerTxtColor}>
              {this.props.jumbotronLead}
            </p>
          </div>
          <div className="row  showAt768">
            <div className="col-md-12 ">
              <ul className="nav justify-content-center">
                {teasers.length > 0
                  ? teasers.map((tease, i) => {
                      return (
                        <li
                          className="nav-item"
                          onClick={this.props.scrollWindow.bind(
                            this,
                            tease.teaserTitle
                          )}
                          key={i}
                        >
                          <strong className="nav-link">
                            {tease.teaserTitle}
                          </strong>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Jumbotron;
