import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import ModalCarousel from "./ModalCarousel";

class Carousel extends Component {
  //CarouselJSON.json

  constructor(props) {
    super(props);
    this.state = {
      autoSlide: true,
      activeItem: undefined,
      showModal: false,
      secondaryContent: [],
      modalContent: [],
      pushImgArr: [],
      text: "",
      hideShow: 0,
      secondaryContent: this.props.secondaryContent,
    };

    this.showGalleryModal = this.showGalleryModal.bind(this);
    this.hideGalleryModal = this.hideGalleryModal.bind(this);
    this.magnifierOver = this.magnifierOver.bind(this);
    this.magnifierOut = this.magnifierOut.bind(this);
  }

  hideShow(whichOne) {
    this.setState({
      hideShow: Number(whichOne),
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  magnifierOver(whichImg) {
    document
      .querySelector(".focusMagnifier[data-num='" + whichImg + "']")
      .classList.add("pulse");
  }

  magnifierOut(whichImg) {
    document
      .querySelector(".focusMagnifier[data-num='" + whichImg + "']")
      .classList.remove("pulse");
  }

  hideGalleryModal() {
    this.setState({
      showModal: false,
    });
  }

  showGalleryModal(contentIndex) {
    this.setState({
      secondaryTitle: this.state.secondaryContent[contentIndex].secondaryTitle,
      showModal: true,
      modalContent: this.state.secondaryContent[contentIndex].secondaryImg,
      modalImgLength: this.state.secondaryContent[contentIndex].secondaryImg
        .length,
    });
  }

  render() {
    const secondaryContent = this.state.secondaryContent;
    let modalContent = this.state.modalContent;

    return (
      <div className="container-fluid" data-section="secondary">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12">
              <h2 className="txtCenter" data-target={this.props.sectionTitle}>
                {this.props.sectionTitle}
              </h2>

              <hr />
            </div>
          </div>
          {secondaryContent.length > 1 ? (
            <div className="btn-group btn-block" role="group">
              {secondaryContent && secondaryContent.length > 0
                ? secondaryContent.map((content, i) => {
                    return (
                      <button
                        type="button"
                        key={i}
                        className={
                          this.state.hideShow === i
                            ? "btn btn-outline-secondary active"
                            : "btn btn-outline-secondary"
                        }
                        onClick={this.hideShow.bind(this, i)}
                      >
                        {content.secondaryTitle}
                      </button>
                    );
                  })
                : null}
            </div>
          ) : null}

          {secondaryContent && secondaryContent.length > 0
            ? secondaryContent.map((content, i) => {
                return (
                  <div
                    className={this.state.hideShow === i ? "row" : "row hide"}
                    key={content.id}
                  >
                    <div
                      className="col-md-3 imgWrap animated fadeIn"
                      data-scroll={i}
                    >
                      <div
                        onMouseOver={this.magnifierOver.bind(this, i)}
                        onMouseOut={this.magnifierOut.bind(this, i)}
                        data-num={i}
                        className="focusMagnifier animated center"
                        onClick={this.showGalleryModal.bind(this, i)}
                      >
                        <i className="fas fa-search-plus" />
                      </div>{" "}
                      <img
                        onClick={this.showGalleryModal.bind(this, i)}
                        src={content.secondaryImg[0]}
                        alt={content.secondaryTitle}
                        className="img-thumbnail"
                      />
                    </div>
                    <div
                      className="col-md-9   animated fadeIn contentTxt"
                      data-scroll={i}
                    >
                      {" "}
                      <h4 className="py-1">{content.secondaryTitle}</h4>
                      <div>{ReactHtmlParser(content.secondaryText)}</div>
                    </div>

                    <hr />
                  </div>
                );
              })
            : null}

          <hr />

          {this.state.showModal === true ? (
            <ModalCarousel
              showModal={this.state.showModal}
              secondaryTitle={this.state.secondaryTitle}
              hideGalleryModal={this.hideGalleryModal}
              modalContent={modalContent}
            />
          ) : null}
        </div>

        <div
          onClick={this.closeModal}
          className="modal-backdrop show"
          style={
            this.state.showModal ? { display: "block" } : { display: "none" }
          }
        />
      </div>
    );
  }
}

export default Carousel;
